
<script>
import Swiper from "swiper";
import  "swiper/css/swiper.min.css"
import { mapActions, mapState ,mapMutations} from 'vuex';
export default {
  data() {
    return {
      //  swiper的图片数据
      swiperdata:[{'spu_id':3,'spu_titleimage': '../../../assets/images/f3.png'}],
      // swiperdata:[],
    };
  },
  computed:{
    // 图片前缀
    ...mapState('mypublic',['PUBLIC']),
    // ...mapGetters('home',['swiperdata'])
  },
 
  methods:{

      // 获取swiper的数值
      // 获取首页spulist数据函数
      async getSwiperData() {   
          // 以后广告多时，用这个URL      
            // let url = "Home/HomeSpuList/show_spulist";
            // 现在广告少，用这个URL
            let url = "Home/GoodsSpu/spu_swiper_showList";
                     // 将参数序列化， 将参数包装在URLSearchParams()中。
                      // let postArguments = new URLSearchParams(myarguments)
                     let {data:res}=await   this.$http.get(url)

                     console.log(res.data);


                     if(res.code==200){

                        // 设置swiper图片数据
                       this.swiperdata=res.data
                                            
                     }
        },

        ...mapMutations('GoodsDetail',[
      // 详情页公共数据set_Final_selected_sku置空
      'set_Final_selected_sku',
       // 给左侧主题图片leftTopicImageURL赋值
       'setleftTopicImageURL',
        // 设置详情页spu数据
       'set_DetailPage_SpuData',
         //1、 该spu展示模板的数据
        'set_show_template',
       

    ]),

            // 跳转到详情页
    gotoGoodsDetail(item) {

// 首先，让详情页的公共数据final_selected_sku置空
  this.set_Final_selected_sku('')

// console.log(spu_id);
// 跳转到详情页之前，先给详情页数据spu_id赋值  setSpuId(state,value)
 // 永久化存储
 localStorage.setItem('home_spu_id',item.spu_id)

 // 将spu_title存入本地，后面sku_title到处要用
 localStorage.setItem('home_spu_title',item.spu_title)

 // 设置详情页spu数据
 this.set_DetailPage_SpuData(item)

  // 该spu展示模板的数据
 this.set_show_template(item.spu_templatetype)

// 跳转到详情页，由模板参数选择详情页的展示模板
 // 跳转到详情页模板A
if(item.spu_templatetype==0){this.$router.push("/GoodsDetail_A");} 
// 跳转到详情页模板B
if(item.spu_templatetype==1){this.$router.push("/GoodsDetail_B");}
  
},
          



    // 复制swiper原版内容
    setSwiper(){
      new Swiper ('.swiper-container', {
      
     loop: true, // 循环模式选项
    
     autoplay: {
        delay: 3000, // 多少秒切换一次swiper;默认3000ms
        disableOnInteraction: false, // 用户操作swiper之后;是否禁止autoplay;默认true;操作包括触碰swiper;拖动swiper;点击pagination
        waitForTransition: false // 是否等待过渡完成swiper;再开始自动切换的计时swiper;默认true
      },

      
       // 如果需要分页器
    pagination: {
      el: '.swiper-pagination',
    },
    
    // 如果需要前进后退按钮
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    
    // 如果需要滚动条
    scrollbar: {
      el: '.swiper-scrollbar',
    },





    // direction: 'vertical', // 垂直切换选项
    //  autoplay: true, // 启动自动切换swiper;
    
    // mousewheel: true, // 是否开启鼠标滚轮控制swiper切换 swiper;默认false
      // direction: 'horizontal',// 滑动方向
      // speed: 300, // 切换速度swiper;自动滑动开始到结束的时间
      // grabCursor:false, // 悬浮时鼠标样式切换
      // centerInsufficientSlides: true, // 当slide总数小于slidesPerView时swiper;slide居中
      // effect: 'fade', // slide的切换效果swiper;默认为&#39;slide&#39;位移切换swiper;&#39; fade&             #39;淡入swiper;&#39;cube&#39;方块swiper;&#39;coverflow&#39;3d流&             #xff0c;&#39;flip&#39;3d翻转swiper;&#39;cards&#39;卡片式swiper;&#39;creative&#39;创意性
      // fadeEffect: {
      //   crossFade: true
      // },

      
      // autoplay: true, // 启动自动切换swiper;等同于以下设置
      // autoplay: {
      //   delay: 3000, // 多少秒切换一次swiper;默认3000ms
      //   disableOnInteraction: true, // 用户操作swiper之后;是否禁止autoplay;默认true;操作包括触碰swiper;拖动swiper;点击pagination
      //   waitForTransition: true // 是否等待过渡完成swiper;再开始自动切换的计时swiper;默认true
      // },


      // loop: true ,
    // 循环模式选项
      // 6.6.2之前的版本需要通过代码实现此功能&#xff1b;开启后swiper;鼠标置于swiper时暂停自动切换swiper;离开时回复自动切换
    //   this.swiper.el.onmouseover = function () { // 鼠标覆盖停止自动切换
    //   this.swiper.autoplay.stop()
    // },
    // this.swiper.el.onmouseout = function () { // 鼠标离开开始自动切换
    //   this.swiper.autoplay.start()
    // },

    // mousewheel:true,
    // keybazrd:true,
   
  }
  ) 

    }
  },
  mounted () {
    this.setSwiper()
  },
  updated(){
    this.setSwiper()
  },
  created(){
    this.getSwiperData()
  }

 
  
}

</script>

<template>
  <!-- 1.首先加载插件，需要用到的文件有swiper-bundle.min.js和swiper-bundle.min.css文件，不同Swiper版本用到的文件名略有不同。可下载Swiper文件或使用CDN。 -->
<!-- <head>
    <link rel="stylesheet" href="dist/css/swiper-bundle.min.css">
</head>
<body>
    <script src="dist/js/swiper-bundle.min.js"></script>
</body> -->
<!-- 2.添加HTML内容。Swiper7的默认容器是'.swiper'，Swiper6之前是'.swiper-container'。 -->
  <div class="swiper-container">
    <div class="swiper-wrapper">
        
        <div class="swiper-slide"
        v-for="item in swiperdata"
        :key="item.banner_id"     
        @click="gotoGoodsDetail(item)"  
        >
        <a href="javascript:;">
         <!-- <img :src="PUBLIC + item.banner_imageurl" :alt="item.swiper_title"> -->
         <img :src="PUBLIC + item.spu_titleimage" >
        </a>
        </div>
        <!-- <div class="swiper-slide">
         <img src="https://img0.baidu.com/it/u=3542748506,4060302361&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500" alt="">
        </div>
        
        <div class="swiper-slide">
         <img src="https://img1.baidu.com/it/u=2841272410,4166724015&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500" alt="">
        </div>
        <div class="swiper-slide">
         <img src="https://img0.baidu.com/it/u=3542748506,4060302361&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500" alt="">
        </div>
        <div class="swiper-slide">
         <img src="https://img0.baidu.com/it/u=4162443464,2854908495&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500" alt="">
        </div>
        <div class="swiper-slide">
         <img src="https://img2.baidu.com/it/u=567357414,4240886412&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500" alt="">
        </div> -->
        <!-- <div class="swiper-slide">Slide 2</div>
        <div class="swiper-slide">Slide 3</div> -->
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>
    
    <!-- 如果需要导航按钮 -->
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
    
    <!-- 如果需要滚动条 -->
    <div class="swiper-scrollbar"></div>
</div>
</template>



<style lang="less" scoped>

.swiper-container {
    width: 100%;
    height: 100%;
    background: blue;
} 
.swiper-wrapper{
  width: 100%;
}
.swiper-container img{
  width: 100%;
  height: 100%;
}

</style>



