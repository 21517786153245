
import axios from 'axios'
// export default createStore({
// 定义一个商品列表模块
const toast = {
    // 模块命名空间
    namespaced: true,
    // 模块里面state必须是函数形式，
    state: {
        // 提示信息数据
        toastMsn: '',
        // 提示信息是否显示
        showFalg:false,
        // 登出页面
        loginoutIsShow:false,

           // 图片前缀
           PUBLIC:'http://localhost/E-shopping-interface/',
           // PUBLIC:'http://47.109.45.55/E-shopping-interface/',
           
    },
    getters: {},
    mutations: {
        
         // 改变是否显示的showFalg数据值，为提示信息设置数据
         changeShowFalg(state, value) {
            // alert(789)
             // 为提示信息设置数据
            state.toastMsn = value
            // 显示提示信息
            state.showFalg = true 
            // 让提示信息3秒后消失
            setTimeout(function(){state.showFalg=false},3000)
            
        }
    },
    actions: {
        
    },
    modules: {}
};

export default toast;