
import axios from 'axios'
import http from '../../components/http'
import router from '@/router';
// import  $http from '@/components/http'
// import 'mains'
// export default createStore({
// 定义一个商品列表模块
const goodsList = {
    // 模块命名空间
    namespaced: true,
    // 模块里面state必须是函数形式，
    state: {
        // 查询到的商品列表数据
        goodsListdata: [],
    },
    getters: {},
    mutations: {
        // // 为商品列表数组设置数据
        setGoodsListdata(state, value) {
            state.goodsListdata = value
        },

    },
    actions: {
       
    },
    modules: {}
};

export default goodsList;