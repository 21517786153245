
<script>
import upPart from "./upPart.vue";
import guessyou from "./guessyou.vue";
import Bottom from "./Bottom.vue";
import keyItem_nav from "./keyItem_nav.vue";
export default {
  components: {
    upPart,
    guessyou,
    Bottom,
    keyItem_nav,
  },
 
};
</script>

<template>
  <div class="homebox">
   
    <!-- 全网搜索 -->
    <AllSearch></AllSearch>
    <keyItem_nav></keyItem_nav>

    <div class="content">

      <!-- 列向大分类、swiper轮播图 -->
    <upPart></upPart>
    <!-- guessyou模块 -->
    <guessyou></guessyou>

    </div>
   
   
  </div>
</template>

<style   lang='less'   scoped>
.homebox{
  width: 100%;
  background: #FFFFFF;
  margin: 0 auto;

  .content{
    width: 90%;
    margin: 0 auto;
  }
}


// image {
//   border: 0;
// }

/*去除浮动float的固定代码：三段。
        只需给需要的标签后面加class="clearfix"即可以*/

// .clearfix:before,
// .clearfix:after {
//   content: "";
//   display: table;
// }

// .clearfix:after {
//   clear: both;
// }

// .clearfix {
//   zoom: 1;
// }

// button,
// input {
//   font-family: Microsoft YaHei, Heiti SC, tahoma, arial;
//   border: 0;
//   outline: none;
// }

/*commons开始*/
/*声明字体图标*/
/**/
/*
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?r6j917");
  src: url("../fonts/icomoon.eot?r6j917#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?r6j917") format("truetype"),
    url("../fonts/icomoon.woff?r6j917") format("woff"),
    url("../fonts/icomoon.svg?r6j917#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}*/
/*版心*/

/*左浮动*/
</style>