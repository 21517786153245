
import Vue from 'vue'
// 5、导入axios包
import axios from "axios";

import router from '../router'
import store from '../store'

// 配置axios的公共路径
// axios.defaults.baseURL = 'http://localhost:8080/'
axios.defaults.baseURL = 'https://www.sscf.top/E-shopping-interface/index.php/'
axios.timeout = 5000

// 请求拦截器
axios.interceptors.request.use(function(config){
  // 取出本地token
  let  token=localStorage.getItem('home_token')

if(token){
//   请求头带上token
  config.headers.token= token   
}

  return config
},function(error){
  
})


// 添加响应拦截器
axios.interceptors.response.use(function(res) {

  //  token验证错误
    if(res.data.code==108){
      
      router.push('/login')
        
    }
    if(res.data.code==1100){
      //  跳转到空白页
      router.push('/empty')
      
    }
      // token验证成功
      // return 必须有，否则报错
      return  res
      
    
 },function(err){}
 
 );


// 将axios全局挂载到app上（vue2）
Vue.prototype.$http=axios
// Vue.use(axios)   这个坚决不能用
