
import axios from 'axios'
import       './GoodsCart'


// 定义一个商品列表模块
const order = {
    // 模块命名空间
    namespaced: true,
    // 模块里面state必须是函数形式，
    state: {

        // 订单商品数据
        orderListData: [],

        // 订单总额
        orderTotalAmount: '',

         // 订单支付方式
         order_paytype:'',

         
           
    },
    getters: {
      
    },
    mutations: {
       
        // 添加订单商品数据
    setOrderListData(state, value) {
        // 给订单数据orderListData赋初始值
            state.orderListData=value 
        },

        // 设置订单结算总金额
        setOrderTotalAmount(state,value){
            state.orderTotalAmount=value
            console.log('飞入寻常百姓家');
            console.log(state.orderTotalAmount);


        }
    },
    
    actions: {

      
    },
    modules: {}
};

export default order;