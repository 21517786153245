import { render, staticRenderFns } from "./UserInfo.vue?vue&type=template&id=506efadf&scoped=true&"
import script from "./UserInfo.vue?vue&type=script&lang=js&"
export * from "./UserInfo.vue?vue&type=script&lang=js&"
import style0 from "./UserInfo.vue?vue&type=style&index=0&id=506efadf&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "506efadf",
  null
  
)

export default component.exports