
import axios from 'axios'
import router from '../../router'


// 定义一个商品列表模块
const address = { //属于资源支持型数据模块
    // 模块命名空间
    namespaced: true,
    // 模块里面state必须是函数形式，
    state: {
    
        // 用户收件全部地址数组
        userAddressData: [],

        // 用户默认收货地址
        defaultuserAddress:'',

        // 用户最终选中的订单送货地址
        order_send_address: '',


          // 用户临时选中使用的地址的数组
        // tempSelectedUserAddressArr: [],
        // 用户最终收货地址
        // address_info: '',
        // 选中即将修改的地址数据
        willUpdataAddress:[],
    },
    getters: {
    
    },
    mutations: {
        

        // 设置该用户地址数组和默认数组
        setuserAddressData(state, value) {  

            // 用户所有收货地址
            state.userAddressData = value

             // 筛选出默认地址数组
            let arr=  value.filter(item=>item.address_isdefault==1)
            // 如果有用户默认数据
            if(arr!==[]){
                   // 默认地址对象，
                state.defaultuserAddress=arr[0]

                // 订单送货地址初始值为默认地址对象
                state.order_send_address = arr[0]
            }
            // 否则，系统默认第一个地址数据为默认地址
            else{

                 // 默认地址对象，
                 state.defaultuserAddress=value[0]

                 // 订单送货地址初始值为默认地址对象
                 state.order_send_address = value[0]

            }
             

            console.log('我是送货地址初始值');
            console.log(state.order_send_address);
        },
        

          // 设置订单送货地址
          setOrder_send_address(state,value){
            state.order_send_address=value
            console.log('空山新雨后');
            console.log( state.order_send_address);

        },

        // // 临时选中作为当前使用的地址数据
        // selected(state,address_id){
        //    state.tempSelectedUserAddressArr= state.userAddress.filter(e=>{
        //       return  e.address_id==address_id//条件语句的等号
        //     })
        //     // 将state.tempSelectedUserAddressArr转化为json数据
        //     let tempSelectedUserAddressArrJSON=JSON.stringify(state.tempSelectedUserAddressArr)
        //     // 将state.tempSelectedUserAddressArrjson数据永久存储
        //     localStorage.setItem('address_info',tempSelectedUserAddressArrJSON)
        // },

      

            

    // 修改默认地址
    setDefaultAddress(state,address_id){
        state.userAddressData.forEach(item=>{
            if(item.address_id===address_id){
                // 将选中的地址值的默认改为1
                item.address_isdefault=1
                //其余全部改为0
            }else{item.address_isdefault=0}
            })
    },
   

    without_use(){
        //  // 设置该用户登录token
    //  setlogintoken(state, value) {  
                
    //     state.token=value
    // },

    // // 设置该用户登录名称
    // setloginuserName(state, value) {             
    //     state.userName=value
    // },

    // // 设置该用户登录token
    // setuserID(state, value) {  
                
    //     state.userID=value
    // },
    // 删除用户登录(退出登录)
    // deleteToken(state){
    //     state.token=''
    //     state.userName=''
    //     state.userID=''
    // },
      // 获取收货地址
    //  getAddress(state){
    // // alert('789')
    // let addr=JSON.parse(localStorage.getItem('address_info'))
    // console.log(addr);
    //   // 最终选中的字符串构造字符串 姓名 地址
    //   let  str=''
    //   addr.forEach(e => {
    //         str+=e.receiver_name+"---"
    //         str+=e.receiver_phone+'---'
    //         str+=e.address_province
    //         str+=e.address_city
            
    //         str+=e.address_area
    //         str+=e.address_detailaddress
    //         console.log(str);
    //         // 将str赋值给address_info
    //         state.address_info= str 
    //         console.log(state.address_info); 
    //     });  
// },
   // // 默认地址数组
    // deleteDefaultAddress(state,address_id){
    //     state.userAddressData=  state.userAddressData
    //      .filter(item=>item.address_id!=address_id)
    // }
    },

    },
    
    actions: {
        // 获取该用户地址全部数据函数
        async getUserAddressData(context) {      
        //    从本地存储获取用户id
            let user_id=localStorage.getItem('user_id')
            // 获取用户数据
            let url = "Home/UserAddress/showlist?user_id=" + user_id;
            let {data : res} = await axios.get(url)
            console.log('空山新雨后，天气晚来秋');
            console.log(user_id);
            console.log(res);
            // 先判断res是否为空
            if(res.code==200){
                 context.commit('setuserAddressData', res.data)
        }else{
           
            // 填写收货地址为空
            // context.commit('setuserAddressData', '')

        }
    },

        // 修改默认地址
         async updataDefultAddress(context,address_id){
            let url = "Home/UserAddress/save_defaultAddress";
            console.log(address_id);
            // 构造传递参数
            let myarguments = {
                address_id:      address_id, 
                };
            // 将参数序列化， 将参数包装在URLSearchParams()中。
             let postArguments = new URLSearchParams(myarguments);
             let {data : res} = await axios.post(url,postArguments) 
             console.log(res);  
            // 先判断res是否为空
            if(res==='修改成功'){
                context.commit('setDefaultAddress', address_id)
                     }
        },
        
         // 删除默认地址
         async deleteDefultAddress(context,address_id){
            let url = "Home/UserAddress/del_false";
            console.log(address_id);
            // 构造传递参数
            let myarguments = {
                address_id:      address_id, 
                };
            // 将参数序列化， 将参数包装在URLSearchParams()中。
             let postArguments = new URLSearchParams(myarguments);
             let {data : res} = await axios.post(url,postArguments) 
             console.log(res);  
            // 先判断res是否为空
            if(res==='修改成功'){
                context.commit('deleteDefaultAddress', address_id)
                     }
        }
    
 },
 modules: {}


}
export default address;