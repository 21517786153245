<template>
  <div class="bottom">
    <div>{{msg}}</div>
    <div class="w">翱翔三十次方，智慧飞进您的生活 </div>
    <div class="beian">
      <a href="https://beian.miit.gov.cn">备案号：京ICP备2024076775号-1</a>
      </div>
    
    <div class="http">三十次方，开启智能人生的神器 https://www.sscf.top</div>
</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
a{
  // list-style: none;
  text-decoration: none;
  color: #eee;
}
 .bottom {
            display: flex;
            justify-content: space-around;
            width: 100%;
            height: 150px;
            // position: fixed;
            // bottom: 0px;
            text-align: center;
            margin-top: 80px;
            margin-bottom: 40px;
            /* background: rgba(33, 33, 66, .2); */
            background: #333355;
            color: #eee;
            line-height: 150px;
            font-size: 16px;
            word-spacing: 10px;
           // font-style: italic;
          //  margin:0  -10px;

        }

</style>
