
import axios from 'axios'
// import toast from '../../components/toast'
// import toast from './toast'

// 商品详情页数据
// 定义一个商品列表模块
const GoodsDetail = {
    // 模块命名空间
    namespaced: true,
    // 模块里面state必须是函数形式，
    state() {
        return{
          // spu的展示模板的数据
          show_template:'',

          //  商品详情页主题标题title
          detailPage_goods_title:'',

           //商品详情页商品价格
          detailPage_goods_price:'',

          //商品详情页商品重量
          detailPage_goods_weight:'',

       
            //  商品详情页主题图片和六张小图片
              spuImageList:'',
             
              // 详情页spu全部数据
              detailPage_spuData: '',  

            

                // 该详情页获取的全部sku商品详情数据
              detailPage_sku_all_data: [],

              // 选中的shu数据
              // selectedSku: '',

              // 是否显示规格显示部分全框图
              isShowBorder:false,

              // 左侧主题图片url
              leftTopicImageURL:'',

              // 商品价格
              goodsPrice : '',

                // 右侧超大图片是否展示控制标识
               flagHugeImage:false,


              //  立即购买和加入购物车按钮的sku指示器,
              // 0=‘该商品已经售罄’
              // -1=‘请选择您需要的商品’
              // 1=有选中的sku并且加入购物车或者立即购买
              nowBuy_addCart_flag:-1,

              // 最终选中的sku值，许多组件共享，直接进入购物车或者订单中
              final_selected_sku:{},

              // 用户购买选中的sku的数量
              user_shopping_num: 1,

        }
    },
    getters: {
        //        // 六张小图数据
        //   sixSmallPictrueData(state){
        //     if(state.skuData !=null && state.skuData.length>6){
        //       // 把对象变成数组
        //       return Array.from(state.skuData).filter((item,index)=> index<6)
        //     }else{
        //       return state.skuData
        //     }
        // } ,
        //  // 商品详情图数组数据
        //  spudetailimage(state){
        //   // 取出商品详情图URL数据字符串 
        //   if(state.spuData){
        //     let  urlstr = state.spuData.spu_detailpicurl   
        //     let   arr=[]
        //     if(urlstr!=null){
        //       // 去掉字符串最后一个逗号
        //       urlstr = urlstr.slice(0,-1)       
        //         //  将字符串转化为数组
        //         arr= urlstr.split(',')     
        //     }
           
        //     return   arr 
        //   }  
        //   return   arr
      
        // },

 

    },
    mutations: {
        //1、 该spu展示模板的数据
        set_show_template(state,value){
          // 展示模板赋值
          state.show_template = value 
      
        },

      
      //1、 获取商品详情页主题图片和六张小图片
      setSpuImageList(state,value){
        // 给六张系统片赋值
        state.spuImageList = value 
        // console.log('花落知多少');
        // console.log(state.spuImageList);
        // 初始化详情页主题图片首图
        // state.leftTopicImageURL=state.spuImageList.spudetailimage_one
          // console.log('齐鲁青未了');
          // console.log(state.spuImageList);
      },

      // 给立即购买和加入购物车的sku指示器赋值
              setNowBuy_addCart_flag(state,value){
                state.nowBuy_addCart_flag = value 
          },

             // 给最终选中的sku值赋值，许多组件共享，直接进入购物车或者订单中
          set_Final_selected_sku(state,value){
            // 给最终选中的sku赋值
            state.final_selected_sku = value 

           // 给最终选中的sku赋值sku_title
        //  state.final_selected_sku.sku_title=localStorage.getItem('home_spu_title')
        //  state.detailPage_goods_title
          // localStorage.getItem('home_spu_title') 

            // 改变详情页商品价格赋值
            state.detailPage_goods_price=value.sku_price

            // 改变详情页商品重量赋值
            state.detailPage_goods_weight=value.sku_weight
            // console.log('烟波江上使人愁');
            // console.log(state.final_selected_sku);
      },
      
      // 设置详情页spu数据
      set_DetailPage_SpuData(state,value){
        // 给详情页spu赋值
        state.detailPage_spuData = value
        // console.log('千里江陵一日还');
        // console.log(state.detailPage_spuData);
        // 给详情页商品价格赋初始值
        state.detailPage_goods_price=value.spu_price
        // console.log(state.detailPage_goods_price);
        // 给详情页商品标题title赋值
        state.detailPage_goods_title=value.spu_title
        // console.log(state.detailPage_goods_title);

         // 给左侧主题图片leftTopicImageURL赋值
        state.leftTopicImageURL=value.spu_titleimage
      },

      
        
        // 5、给详情页全部sku数据detailPage_sku_all_data赋值，。
        set_detailPage_sku_all_data(state,value){
          state.detailPage_sku_all_data = value 
          // console.log( state.skuData);
        },
        
        

        // 7、显示规格栏目全框图
        setIsShowBorder(state){
            // 显示选项框
            state.isShowBorder=true
        },
    

    // 给左侧主题图片leftTopicImageURL赋值,详情页主题图片是变换的
       setleftTopicImageURL(state,value){
        state.leftTopicImageURL=value
    },


    
              // 7、改变用户购买商品数量
              set_user_shopping_num(state,value){
                // 显示选项框
                state.user_shopping_num=value
            },
    

     

    // 无用数据
    unuseful(){

    //   // 1 选择规格
    //   setSelectSku(state,item){ 
    //      state.selectedSkuSpecsValue[item.key]=item.value 
    //     //  console.log(state.selectedSkuSpecsValue);
    //      let flag = state.selectedSkuSpecsValue.some(item=>item == 0)
    //       // console.log(flag);
    //       if(flag){
    //         alert('请选择您要的商品信息')
    //         // toast.commit.changeShowFalg('请选择您要的商品信息')
            
    //         // 显示选项框
    //         state.isShowBorder=true
    //       }else{
    //      //  将state.selectSku字符串化
    //   let selectedSkuSpecsValueStr=state.selectedSkuSpecsValue.join(',')
    //     // console.log(selectedSkuSpecsValueStr);
    //     // 选择出选中的sku值
    //     state.selectedSKU=state.skuData.find(item=>item.sku_value===selectedSkuSpecsValueStr)
    //     if(state.selectedSKU){
    //       // console.log(state.selectedSKU);
    //       // // 隐藏选项框
    //       // state.isShowBorder=false
    //       // // 修改商品价格为sku价格
    //       // state.goodsPrice=state.selectedSKU.sku_price
    //       // // 修改商品主题图片
    //       // state.leftTopicImageURL=state.selectedSKU.sku_imageurl
  
  
    //      //  构造出即将进入购物车的数据
      
    //   // let  v= state.goodsCartDataItem
      
      
    //   // 购物车是否被选择
    //   state.selectedSKU.isSelected=false
    //   // 购物车选中的sku_id，是该商品的唯一标识，可以让购物车商品数据去重
    //   // v.id=state.selectedSKU.sku_id
    //   // // 购物车图片，是选中的sku图片
    //   // v.imageURL=state.selectedSKU.sku_imageurl
    //   // // 购物车主题词，是spu的title
    //   state.selectedSKU.title=state.spuData.spu_title
    //   // // 购物车价格，是选中的sku的价格
    //   // v.price=state.selectedSKU.sku_price
    //   // // 购物车的数量
    //   state.selectedSKU.num=1
     
    //   // 将state.selectedSKU.sku_value转化为数组
    //   let w=state.selectedSKU.sku_value.split(',')
    //   // console.log(w);
    //   // console.log(state.specsData);
    //   // 购物车的sku规格选项和规格值
    //   state.selectedSKU.specsvalue=''
    //   state.specsData.forEach((item,index) => {
    //     state.selectedSKU.specsvalue+=item.specs_name + ':' + w[index] + ','
    //   });
    //   // v.specsvalue去掉最后一个逗号
    //   state.selectedSKU.specsvalue=state.selectedSKU.specsvalue.slice(0,-1)
    //   // console.log(v.specsvalue);
    //   // console.log(state.selectedSKU);
    
         
    //      alert('去购物车')
  
    //     }else{
    //       alert('缺货')
    //     }
      
    //       }
        
    // } , 

    
  //  //  构造出即将进入购物车的数据
  //  structureGoodsCartdata(state){
    
  //   // 构造购物车数据
  //   let  v=state.goodsCartDataItem
    
  //   // 购物车是否被选择
  //   v.isSelected=false
  //   // 购物车选中的sku_id，是该商品的唯一标识，可以让购物车商品数据去重
  //   v.id=state.selectedSKU.sku_id
  //   // // 购物车图片，是选中的sku图片
  //   v.imageURL=state.selectedSKU.sku_imageurl
  //   // // 购物车主题词，是spu的title
  //   v.title=state.spuData.spu_title
  //   // // 购物车价格，是选中的sku的价格
  //   v.price=state.selectedSKU.sku_price
  //   // // 购物车的数量
  //   v.num=1
  //   // 购物车该商品的金额小计
  //   // v.amount=(v.num) * (v.price)
    
  //   // 将state.selectedSKU.sku_value转化为数组
  //   let w=state.selectedSKU.sku_value.split(',')
  //   // console.log(w);
  //   // console.log(state.specsData);
  //   // 购物车的sku规格选项和规格值
  //   v.specsvalue=''
  //   state.specsData.forEach((item,index) => {
  //     v.specsvalue+=item.specs_name + ':' + w[index] + ','
  //   });
  //   // v.specsvalue去掉最后一个逗号
  //   v.specsvalue=v.specsvalue.slice(0,-1)
  //   // console.log(v.specsvalue);
  //   console.log(v);

  //   // 将构造好的v数据全部push到购物车数据中去
    
        //  // 2、首先，给spuData赋值。
      //         setSpuData(state,value){
      //           state.spuData = value 
      //     },
        
          // 3、首先，给specsData赋值。给被选中的规格值赋初始值
        //   setSpecsData(state,value){
        //     // 给specs赋值
        //     state.specsData = value 
        //       // 给selectSku初始化赋值，每个初始值为0
        //     state.specsData.forEach((item,index) => {
        //       state.selectedSkuSpecsValue[index] = 0 
        //     });
        //     // console.log(state.selectedSkuSpecsValue);
        // },
        
        // 4、给specsvalueData赋值。
        // setSpecsvalueData(state,value){
        //   state.specsvalueData = value 
        // },

        // 给详情页展示的商品价格赋值
  //    set_detailPage_goodsPrice(state,value){
  //     state.goodsPrice = value
      
  // },

       // 给详情页选中的sku值赋值，为购物车准备数据
    //    setSelectedSKU(state,value){
    //     state.selectedSKU=value
    // },
    // // 6、给选中的sku赋值
        // setSelectedSku(state,value){
        //   state.selectedSku=value
        //   // console.log(state.selectedSku);
        //   // 因为有前面把state.selectedSku置空，因此需要判断state.selectedSku是否为空
        //   if(state.selectedSku){
        //   // 隐藏选项框 // 隐藏规格栏目全框图
        //   state.isShowBorder=false
        //   // sku确定后，可以连带确认商品价格，主体图片
        //  // 修改商品价格为sku价格
        //   state.goodsPrice=state.selectedSku.sku_price 

        //   // 修改商品主题图片
        //   // state.leftTopicImageURL=state.selectedSku.sku_imageurl
        // }
        // },
  }


  
  },

   actions: {

      // 获取商品详情页主题图片和六张小图片
      async getDetailImage(context) {
        // action中使用state数据方法 context.state.spu_id
        // const spuID= context.state.spu_id       
        const spu_id= localStorage.getItem('home_spu_id')
        
        // console.log('刚刚');
        // console.log(spu_id);
          // 构造url字符串
          let url = "Home/GoodsSpuDetailImage/spu_detailpage_showList/?spu_id=" + spu_id;   
          let {data : res}= await axios.get(url)
          // console.log('齐鲁青未了');
          // console.log(res.data);
          if(res.code==200){
               // 给spuData赋值
               context.commit('setSpuImageList', res.data)

          }

        },

        // 获取spu商品详情页数据函数
    //  async get_detailPage_spuData(context) {
    //   // action中使用state数据方法 context.state.spu_id 
    //   const home_spu_id= localStorage.getItem('home_spu_id')      
    //     // 构造url字符串
    //     let url = "Home/GoodsSpu/spu_detailpage_showList/?spu_id=" + home_spu_id;   
    //     let {data : res}= await axios.get(url)
    //     // console.log('会当凌绝顶');
    //     // console.log(res.data);

    //     // 给spuData赋值
    //       context.commit('set_DetailPage_SpuData', res.data)

    //       // 将spu_title存入本地，后面sku_title到处要用
    //       localStorage.setItem('home_spu_title',res.data.spu_title)
    //       // 给skuData赋值
    //       // context.commit('setleftTopicImageURL', res.spu_mainpicurl)
         
    //       // 给详情页赋spu的价格值
    //       // context.commit('setGoodsPrice', res.data.spu_price)
    //   },

        // 获取该商品的sku全部数据函数
    //  async getSpecsData(context) {
    //   // action中使用state数据方法 context.state.spu_id
    //   // const spu_id= context.state.spu_id    
    //   const spu_id= localStorage.getItem('home_spu_id')   
    //   // 构造url字符串
    //   let url = "Home/HomeShowList/showlist_specs/?id=" + spu_id; 
    //   let {data : res}= await axios.get(url)
    //   // console.log(res);
    //   // // 给specsData赋值
    //     context.commit('setSpecsData', res)
    //     // 初始化原始规格数据
    //     // context.commit('initializeSelectSku')
    // },
   
      //     // 获取specsvalue商品规格数据函数
      //     async getSpecsvalueData(context) {
      //       // action中使用state数据方法 context.state.spu_id
      //      // const spu_id= context.state.spu_id    
      // const spu_id= localStorage.getItem('home_spu_id')      
      //       // 构造url字符串
      //       let url = "Home/HomeShowList/showlist_specsvalue/?id=" + spu_id;  
      //       let {data : res}= await axios.get(url)  
      //       console.log(res);
      //       // 给 specsvalue赋值
      //         context.commit('setSpecsvalueData', res)
 
      //     },
 

      // 获取sku的值
      async get_detailPage_sku_all_data(context) {   
         // const spu_id= context.state.spu_id    
      const home_spu_id= localStorage.getItem('home_spu_id')   
        //需要传的参数  spu_id是上页传递过来的参数，固定写法      
        let url = "Home/GoodsSku/detailPage_spu_id_sku_showlist/?spu_id=" + home_spu_id;   
        let {data : res}= await axios.get(url)
        // console.log(res.data);
        
        // 给每一个sku值赋予sku_title值
        if(res.data){
        res.data.map(item=>item.sku_title
          =localStorage.getItem('home_spu_title'))
        }
        // console.log('牧童遥指杏花村');  
        // console.log(res.data);   
        // console.log(res.data[0].sku_value);   
        // 给goodsSkuData赋值
          context.commit('set_detailPage_sku_all_data', res.data)
      },
   
   },

    modules: {}
  }


export default GoodsDetail;