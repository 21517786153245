
<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
    name:'spu',
//     props:{
//        // 传递给分页组件的数据请求路径URL
//         pageDataRequestUrl:{
//         type:String,
//         // required:true,
//         default:'',
//       },
    
// },

    data(){
        return {
        //    选中的页码数
        selectedPageNum:1,                                        
        }
    },
    

    computed:{
            // 页码总数量\展示接口地址
        ...mapState('pageCode',['pageTotal','showPageDataApiUrl'])
    },
    
    methods:{
        ...mapActions('pageCode',['getPageListData',]),
     
      
        // 获取页面展示数据
        getData(item){

            // 选中的页码颜色变色 
            this.selectedPageNum=item
          
            let url = this.showPageDataApiUrl
            // +"&page_id=" + item; 
            console.log(url); 
            // 调用分页器获取页面数据,传递选中的页码数和要请求的地址参数字符串
            this.getPageListData(url)
        },
    },

    created(){
        this.getData(1)
    }

}
</script>


<template>
    <div class="pageNumBox">
        <!-- 当页面总数目小于等于10个时 模块-->
  <ul class="pagecode"  v-if="pageTotal<=10">
    <li v-for="item in pageTotal" :key="item" :class="{activity:item==selectedPageNum}">
        <a href="javascript:;"
        @click="getData(item)"
        >{{item}}</a>
    </li>
    <li class="pageTotal">共：{{pageTotal}}页</li>
  </ul>

         <!-- 当页面总数目大于10个时 模块-->
         <ul class="pagecode"  v-if="pageTotal>10">
            <li  :class="{activity:item==selectedPageNum}" class="upPage">
        <a href="javascript:;"
        @click="getData(item)"
        > ＜上一页</a>
    </li>
    <li v-for="item in 10" :key="item" :class="{activity:item==selectedPageNum}">
        <a href="javascript:;"
        @click="getData(item)"
        >{{item}}</a>
    </li>
    <li  :class="{activity:item==selectedPageNum}"   class="downPage">
        <a href="javascript:;"
        @click="getData(item)"
        >下一页></a>
    </li>
    <li  class="pageTotal">共：{{pageTotal}}页</li>
  </ul>
 
</div>
</template>



<style  lang='less'  scoped>
// .pageNumBox{
//     width: 100%;
// }
.pagecode{
    display: flex;
    justify-content: center;
    // width: 100%;
    height: 100px;
    margin: 30px auto;
  
    li{
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background: #eeeeee;
        line-height: 30px;
        text-align: center;
        margin-right: 30px;
        a{
            font-size: 14px;
            color: rgba(57, 57, 228, 0.915);
        }

    }
    // 总共页数
    .pageTotal{
        width: 120px;
        height: 30px;
    }
    // 上一页、下一页
    .upPage,  
    .downPage{
        width: 65px;
        height: 30px;
    }
    li:hover{
        background: rgba(57, 57, 228, 0.915);
        a{
            color: #FFFFFF;}
        
    }
    // 选中的页码样式
    .activity{
        background: rgba(57, 57, 228, 0.915);
        a{
            color: #FFFFFF;}
    }

}

</style>