
import axios from 'axios'
// import       './GoodsCart'


// 定义一个商品列表模块
const Pay = {
    // 模块命名空间
    namespaced: true,
    // 模块里面state必须是函数形式，
    state: {

                //  构造订单支付数据
                  // 订单编号
                    order_id:   '',
                    // 订单名称（收款方名称）
                    order_name:     '',
                    // 订单编号
                    order_number:   '',
                    // 订单总金额
                    order_total :   '',
                    // 订单明细
                    order_item  :   '',
                        
                    // 订单支付方式,支付宝1，微信2，银联3，未选择0
                    pay_method_num:  0,
                    
                    // 临时定义显示页面
                    show_num:1,
                    
                    // 微信支付传递过来显示二维码的地址
                    weixinPay_text:'',
    },
    getters: {
    },
    mutations: {
        // 设置订单支付需要的数据
       set_order_Pay_Data(state, value) {
            // 订单id
            state.order_id=value.order_id
           // 订单名称
            state.order_name=value.order_name
            // 订单编号
            state.order_number=value.order_number
            // 订单总额
            state.order_total=value.order_total
            // 订单商品明细
            state.order_item=value.order_item
            

        },

          // 设置支付方式值
        set_pay_method_num(state,value){
            state.pay_method_num=value
                    },

        // 设置微信支付传递过来显示二维码的地址
        set_weixinPay_text(state,value){
          // alert(value)
          state.weixinPay_text=value
                  }
                    
    },
    
    actions: {},
    modules: {},
};

export default Pay;