
<script>
import { mapActions, mapState,mapGetters,mapMutations } from "vuex";
export default {
  data() {
    return {
      // spulist首页广告数据
      spulist: [],
    };
  },
  computed:{
    // 图片前缀
    ...mapState('mypublic',['PUBLIC']),
   
  },


  methods: {

    
    ...mapMutations('pageCode',['setShowPageDataApiUrl']),
    
    ...mapMutations('GoodsDetail',[
      // 详情页公共数据set_Final_selected_sku置空
      'set_Final_selected_sku',
       // 给左侧主题图片leftTopicImageURL赋值
       'setleftTopicImageURL',
        // 设置详情页spu数据
       'set_DetailPage_SpuData',
         //1、 该spu展示模板的数据
        'set_show_template',
       

    ]),

     // // 获取首页spulist数据函数
        async getSpuList() {   
          // 以后广告多时，用这个URL      
            // let url = "Home/HomeSpuList/show_spulist";
            // 现在广告少，用这个URL
            let url = "Home/GoodsSpu/spu_ALL_showList";
                     // 将参数序列化， 将参数包装在URLSearchParams()中。
                      // let postArguments = new URLSearchParams(myarguments)
                     let {data:res}=await   this.$http.get(url)
                     console.log(res.data);

                     if(res.code==200){

                        // 设置商品列表数据
                       this.spulist=res.data
                                            
                     }


        },


    
   // 跳转到详情页
   gotoGoodsDetail(item) {

// 首先，让详情页的公共数据final_selected_sku置空
  this.set_Final_selected_sku('')

// console.log(spu_id);
// 跳转到详情页之前，先给详情页数据spu_id赋值  setSpuId(state,value)
 // 永久化存储
 localStorage.setItem('home_spu_id',item.spu_id)

 // 将spu_title存入本地，后面sku_title到处要用
 localStorage.setItem('home_spu_title',item.spu_title)

 // 设置详情页spu数据
 this.set_DetailPage_SpuData(item)


  // 该spu展示模板的数据
 this.set_show_template(item.spu_templatetype)

//  console.log('禅房花木深');
 // 跳转到详情页模板A
if(item.spu_templatetype==0){this.$router.push("/GoodsDetail_A");} 
// 跳转到详情页模板B
if(item.spu_templatetype==1){this.$router.push("/GoodsDetail_B");}
  
},
    
  },

  created(){
    // 获取首页的spulist广告数据
    this.getSpuList()
  }
};
</script>

<template>

  <div class="guessyoubox">
    <!-- 标题 -->
    <div class="header">
      <p class="titlebox">
        <span class="title">猜你喜欢</span>
        <span class="subtitle">个性推荐</span>
      </p>
        
    </div>

    <!-- 商品图片展示 -->
      <ul class="goodsListShow" >
        <li  v-for="item in spulist" :key="item.spu_id"
        class="goodsbox"
        @click="gotoGoodsDetail(item)"
        >
        <!-- <a href="JavaScript:;"> -->
        <div class="pictrue">
          
          <img :src="PUBLIC + item.spu_titleimage" >
        
        </div>
      
        <div class="text">
          <p class="goodsname">{{item.spu_title}}</p>
          <p class="price">￥{{item.spu_price}}</p>
        </div>
      <!-- </a> -->
      </li>
       
      </ul>
    
  </div>
</template>



<style lang='less' scoped>

.guessyoubox{
  width: 100%;
  margin: 0 auto;
  // 标题
  .header{
    height: 80px; 
    padding-top: 30px;  
    .titlebox{
      display: flex;

      align-items:center ;
       padding-left: 30px;
      width: 100%;
      height: 50px;
      // background: blue;
      .title{
      font-size: 26px;
      // 文字间距
      letter-spacing: 4px;
      color: #666666;
    }
    .subtitle{
      background: #f74141;
      color: #FFFFFF;
      margin-left: 5px;
    }

    }
   

  }
  // 商品
  .goodsListShow{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    align-items:flex-start;
    width: 100%;
    padding: 20px;
    // margin: 0 10px;
    // a{
    //   width: 100%;
    //   height: 100%;
    .goodsbox{
      display: flex;
      width: 32%;
      height: 160px;
      // border: 1px solid  #f74141;
      background-color: #f7f9fa;
      border-radius:10px;
      overflow: hidden;
      margin-bottom: 20px;
      padding: 10px;
      
      
      .pictrue{
        flex:2;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
          }
      }
      .text{
        flex:3;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content:space-between;
        padding: 5px 0 5px 10px;
        color: #666666;
        .goodsname{
          font-size: 16px;
          color: #666666;
          //文字最多显示3行， 限制超长文字
          overflow:hidden; 
          text-overflow:ellipsis;
          display:-webkit-box; 
          -webkit-box-orient:vertical;
          -webkit-line-clamp:3; 
         
        }
        .price{
          font-size: 20px;
          font-weight: 700;
          color: #f74141;
        }
      }

    }
    // }

  }
}
// 商品框线显示
.goodsListShow .goodsbox:hover{
        border: 1px solid  #f74141;
        background-color: #FFFFFF;
      }




.no{

  /* .recom {
  height: 163px;
  width: 90%;
  background-color: #ebebeb;
  margin: 20px auto;
  
}

.recom-hd {
  position: relative;
  float: left;
  height: 163px;
  width: 205px;
  background-color: #5c5251;
  text-align: center;
  line-height: 163px;
  background: #a22;
}

.recom-hd p {
  position: absolute;
  top: 30px;
  left: 65px;
  font-size: 18px;
  color: #fff;
  
}

.recom-bd {
  float: left;
  height: 163px;
}

.recom-bd ul li {
  float: left;
  position: relative;
}

/*峈嚙誼瘀蕭砦嚙踝蕭怢嚙踝蕭埜嚙誕湛蕭芞⑵奀嚙踝蕭嚙踝蕭苤嚙踝蕭珨嚙踝蕭嚙踝蕭芞⑵嚙踝蕭苤迡嚙踝蕭.嚙踝蕭嚙諂塚蕭嚙踝蕭嚙踝蕭嚙踝蕭嚙趣﹝嚙踝蕭嚙踝蕭嚙踝蕭嚙踝蕭荌嚙趣票嚙踝蕭*/

// .recom-bd ul li img {
//   width: 248px;
//   height: 163px;
// }

// .recom-bd ul li:nth-child(-n + 3)::after {
//   content: "";
//   position: absolute;
//   right: 0px;
//   top: 10px;
//   width: 1px;
//   height: 145px;
//   background-color: #ccc; */
// }


}
</style>