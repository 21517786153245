
<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<template>
  <div class="bottom">
    <div>{{msg}}</div>
    <div class="w">翱翔三十次方，智慧飞进您的生活 </div>
    <div class="beian">
      <a href="https://beian.miit.gov.cn"  rel="noreferrer" target="_blank">
        京ICP备2024076775号-1
      </a>
      
      </div>
      <div class="beian">
      
      <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11011202100771"    
           rel="noreferrer" target="_blank">

        <div class="guohui">
           <img src="../assets/beianguohui.png" alt="">
        </div>  
      </a>
      </div>
      <div class="beian">
       
      <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11011202100771" rel="noreferrer" target="_blank">京公网安备11011202100771</a>
      </div>
    
    <div class="http">三十次方，开启智能人生的神器 https://www.sscf.top</div>
</div>
</template>




<style scoped lang="less">
 .bottom {
            display: flex;
            justify-content: space-around;
            width: 100%;
            height: 50px;
            text-align: center;
            margin-top: 20px;
            margin-bottom: 10px;
            /* background: rgba(33, 33, 66, .2); */
            // background: #333355;
            background: #fff;
            color: #999;
            border-top: 1px solid #ccc;
            line-height: 50px;
            font-size: 16px;
            word-spacing: 3px;

        }
        .guohui{
          display: flex;
          justify-content:center ;
          align-items: center;
          width: 50px;
          height: 50px;
          line-height: 50px;
          // background: red;
          overflow: hidden;
          img{
            width: 18px;
            height: 20px;
          }
        }

        a{
          color: #999;
        }
        a:hover{
          color: #f88;
        }

</style>
