
<script>

// import Swiper from "../../Swiper";
import Swiper from "./Swiper.vue";
import category_nav from "./category_nav.vue";
import UserInfo from "./UserInfo";
export default {
  components: {
    Swiper,
    category_nav,
    UserInfo,
  },
};
</script>

<template>
  <ul class="focusbox">
    
      <li class="li_myleft">
        <!-- 列向导航 -->
        <category_nav></category_nav>
      </li>
      <li class="li_mymid">
        <!-- swiper图片 -->
        <Swiper></Swiper>
      </li>
      <li class="li_myright">
        <!-- swiper图片 -->
        <UserInfo></UserInfo>
      </li>
 
    </ul>
</template>

<style  lang='less'  scoped>
/* 页面版心 */
.focusbox {
  display: flex;
 
  width: 100%;
  margin: 0 auto;
  height: 400px;
 
  
  .li_myleft {
   flex:1;
  // width: 300px;
  background: #f0f4f0;
  border-radius: 5%;
  margin-left: 20px;
  
}
.li_mymid {
  flex:2;
  background: blue;
  border-radius: 3%;
  overflow: hidden;
  margin:0 20px;
  
}
.li_myright {
  flex:1;
  border-radius: 3%;
  overflow: hidden;
  margin:0 auto;
  
}

  
  
}


</style>