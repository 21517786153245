

<script>
import { mapActions, mapState ,mapMutations} from 'vuex';
import pageCode       from "../../components/pageCode.vue"
import ListSearch     from "./ListSearch.vue"
import ShowList       from "./ShowList.vue"
export default {
  components:{ 
              pageCode,
              ListSearch,
              ShowList,
               },
 
}
</script>

<template>
  <div class="GoodsListbox">
    <ListSearch></ListSearch>
    <ShowList></ShowList>

</div>
</template>

<style lang="less"   scoped>

.goodsListBox{
  width: 100%;
  height: 100%;
}

</style>