
import axios from 'axios'

// 定义一个商品列表模块
const mine = {
    // 模块命名空间
    namespaced: true,
    // 模块里面state必须是函数形式，
    state() {
        return{
            //   是否显示的数码
            isShow_num: 1,

        }
           
    },
    getters: {},
    mutations: {

        // 设置是否显示的数字
        set_isShow_num(state,value){
            state.isShow_num = value;              
        },

    },
    actions: {},
    modules: {}
};

export default mine;