
<script>

export default {
methods:{

  // 去搜素的按钮方法，去商品列表页面
    goSearch(searchKey){
          // 再保存搜索关键字到本地
          localStorage.setItem('searchKey',searchKey)   

           // 跳转到goodslist页面即可，不做查询
           this.$router.push('/GoodsList');
              
                  
  },
  
},

}

</script>

<template>
  <!--/*nav导航模块制作start*/-->
  <ul class="homenavbox">
              <li class="title">分类</li>
             
     <li>
        <a  href="javascript:;"   class="mya" 
         @click="goSearch('大数据处理')">大数据处理
        </a>/
        <a  href="javascript:;"   class="mya" 
         @click="goSearch('软件开发')">软件开发
        </a>
     </li>

     <li>
        <a  href="javascript:;"   class="mya" 
         @click="goSearch('玩具')">玩具
        </a>/
        <a  href="javascript:;"   class="mya" 
         @click="goSearch('信息服务')">信息服务
        </a>
     </li>

     <li>
        <a  href="javascript:;"   class="mya" 
         @click="goSearch('区块链')">区块链
        </a>/
        <a  href="javascript:;"   class="mya" 
         @click="goSearch('3D建模')">3D建模
        </a>
     </li>

     <li>
        <a  href="javascript:;"   class="mya" 
         @click="goSearch('工业陶瓷')">工业陶瓷
        </a>/
        <a  href="javascript:;"   class="mya" 
         @click="goSearch('卫星遥感')">卫星遥感
        </a>
     </li>

     <li>
        <a  href="javascript:;"   class="mya" 
         @click="goSearch('文艺创作')">文艺创作
        </a>/
        <a  href="javascript:;"   class="mya" 
         @click="goSearch('图文设计')">图文设计
        </a>
     </li>

     <li>
        <a  href="javascript:;"   class="mya" 
         @click="goSearch('造价咨询')">造价咨询
        </a>/
        <a  href="javascript:;"   class="mya" 
         @click="goSearch('数字文化')">数字文化
        </a>
     </li>

     <li>
        <a  href="javascript:;"   class="mya" 
         @click="goSearch('建筑智能化')">建筑智能化
        </a>/
        <a  href="javascript:;"   class="mya" 
         @click="goSearch('智能汽车')">智能汽车
        </a>
     </li>

     <li>
        <a  href="javascript:;"   class="mya" 
         @click="goSearch('工程管理')">工程管理
        </a>/
        <a  href="javascript:;"   class="mya" 
         @click="goSearch('园艺软装')">园艺软装
        </a>
     </li>


              
            
             
              <!-- <li><a href="#">家用电器</a></li>
              <li><a href="#">手机、数码、通信</a></li>
              <li><a href="#">家用电器</a></li>
              <li><a href="#">电脑、办公</a></li>
              <li><a href="#">家具、家居、家装、厨具</a></li>
              <li><a href="#">男装、女装、童装、内衣</a></li>
              <li><a href="#">个护化妆、清洁用品、宠物</a></li>
              <li><a href="#">鞋靴、箱包、珠宝、奢侈品</a></li>
              <li><a href="#">运动户外、钟表</a></li>
              <li><a href="#">汽车、汽车用品</a></li>
              <li><a href="#">母婴、玩具乐器</a></li>
              <li><a href="#">食品、酒类、生鲜、特产</a></li>
              <li><a href="#">医药保健</a></li>
              <li><a href="#">图书、音响、电子书</a></li>
              <li><a href="#">彩票、旅行、充值、票务</a></li>
              <li><a href="#">家用电器</a></li>
              <li><a href="#">理财、众筹、白条、保险</a></li> -->
       
               <!--/*navitems模块制作start*/
        <ul class="navitems"> 
            <li><a href="">服装城</a></li>
            <li><a href="">美妆馆</a></li>
            <li><a href="">传智超市</a></li>
            <li><a href="">全球购</a></li>
            <li><a href="">闪购</a></li>
            <li><a href="">团购</a></li>
            <li><a href="">拍卖</a></li>
            <li><a href="">有趣</a></li>
        </ul> -->
        </ul>

</template>




<style  lang='less'  scoped>
/*nav模块制作*/
.homenavbox {
  display: flex;
  flex-direction: column;
  width: 100%;
  
  
  li {
    width: 100%;
    height: 44px;
    padding-left: 30px;
    padding-right: 20px;
    
    a{
      color: #333333;
      font-size: 14px;
      line-height: 44px;
    }
  }

}

.title{
  font-size: 16px;
  font-weight: 700;
  line-height: 44px;
}



/*伪元素*/

// .dropdown .dd ul li::after {
//   display: block;
//   position: absolute;
//   right: 10px;
//   top: 1px;
//   /*声明字体*/
//   font-family: "icomoon";
//   content: "\ea42";
//   color: #fff;
//   font-size: 12px;
// }

// .dropdown .dd ul li:hover {
//   background-color: #fff;
// }

// .dropdown .dd ul li a {
//   font-size: 14px;
//   color: #fff;
// }

// .dropdown .dd ul li a:hover {
//   display: block;
//   padding-left: 10px;
//   color: #c81623;
// }

// .navitems ul li {
//   float: left;
// }

// .navitems ul li a {
//   display: block;
//   height: 45px;
//   line-height: 45px;
//   padding: 0 25px;
//   font-size: 16px;
//   color: black;
// }

// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }
</style>