
import axios from 'axios'

// 定义一个商品列表模块
const GoodsCart = {
    // 模块命名空间
    namespaced: true,
    // 模块里面state必须是函数形式，
    state() {
        return {
            // 购物车数据
            goodsCartListData:[],
            // goodsCart_all_list:[],
            
                     
            // 已经选中的购物车数据,// 亦即订单商品数据
            selectedCartListData: [],

            // 选中的购物车商品的总金额
            selectedTotalAmount: 0.00,

            // 选中的购物车总件数
            selectedCartTotalNumber: 0,

              // 全选值
            selectAllData:false,
            
        }

    },

    getters: {
    },
    mutations: {

        // 1、给购物车全部商品数据goodsCartListData赋值，。
        set_goodsCartListData(state,value){
          state.goodsCartListData = value
          // console.log('月落乌啼霜满天');
          // console.log(state.goodsCartListData);
        },

      

        // 设置被选中的商品数据selectedCartListData
        setSelectedCartListData(state,value){

            // 设置被选中的购物车sku数据
            state.selectedCartListData=value

            // 简化选中购物车数据书写而写
            let w=state.selectedCartListData
         
            // 结算被选中的商品的总件数
            state.selectedCartTotalNumber=w.reduce((prev,current)=>prev+Number(current.sku_num),0)

            // 首先给每个选中商品的添加一项金额小计amout属性
            let z=w.map(item=>item.amount=item.sku_num * item.sku_price)
           
             // 结算被选中的商品的总金额
           state.selectedTotalAmount=w.reduce((prev,current)=>prev+current.amount,0).toFixed(2)

        },


        // 删除按钮，删除商品
        delSkuItem(state, value) {
          // console.log(value);
          let v=  state.goodsCartListData
        //    过滤即删除
          state.goodsCartListData=v.filter(item=>item.sku_id!=value.sku_id)

        },

        // 删除已经加入订单的数据，由订单页面的‘提交订单’按钮触发
        deleteEnteredOrderSku(state){
           let s=state.goodsCartListData
           state.goodsCartListData=s.filter(item=>item.isSelected==false)
        },
       
      //   // 全选按钮
      //   selectAllbutton(state,value){      
      //      state.goodsCartListData.forEach(item => {
       
      //   item.isSelected=value
        
      // });   
      //   },


         // 清空购物车
      emptyCart(state){
        // 选让内存goodsCartListData为空
        state.goodsCartListData=[]
        // 再让本地存储goodsCartListData清空
        localStorage.setItem('goodsCartListData','')
      
      },

    
         

            // 无用函数
            withoutUse(){
          //永久化本地存储的购物车数据
      //    foreverStorageGoodsCartListData(state){
      //     let goodsCartListstr=''
      //     if(state.goodsCartListData){
      //        // // 先将购物车数据json化
      //      goodsCartListstr =JSON.stringify(state.goodsCartListData)
      //     }
        
      //     // 再购物车数据永久化存储
      //     localStorage.setItem('goodsCartListData', goodsCartListstr)
      // },

              // 给购物车数据赋初始值
      // initializeGoodsCartListData(state,value){

      //   this.goodsCartListData=value
      //   // console.log(this.goodsCartListData);
      //   // console.log(this.goodsCartListData[0]);
      //   // console.log(this.goodsCartListData[0]['sku_title']);
      //   // 从本地存储取出购物车字符串数据
      //   // let w=localStorage.getItem('goodsCartListData')
      //   // 判断本地存储是否有购物车数据，
      //   // if(w){
      //   //    // 如果本地有购物车数据，先将购物车字符串数据json解码为对象数组数据,再赋值给购物车数组
      //   // state.goodsCartListData=JSON.parse(localStorage.getItem('goodsCartListData'))
      //   // }
      
      // },

        // 给购物车添加数据，由详情页面的‘加入购物车’按钮调用
        // addgoodsCartListData(state,value){
          
        //   // 判断购物车里面有没有这个新加入的sku的sku_id值，即去重
        //  let w= state.goodsCartListData
        // let s= w.find(item=>item.sku_id==value.sku_id)
        //  if(s){
        //     // 这个sku_id的num值+1，即可
        //     s.num+=1
        //  }
        //  else{//否则，将这个sku对象全部元素加入到购物车数据  

        //     //2、 给购物车添加新数据
        //     state.goodsCartListData.unshift(value)
        
        //      //先将购物车数据json化
        //     let goodsCartListstr =JSON.stringify(state.goodsCartListData)
        //     // console.log(goodsCartListstr);
        //     // // 再购物车数据永久化存储
        //     localStorage.setItem('goodsCartListData', goodsCartListstr)
        //     // console.log(localStorage.getItem('goodsCartListData'));
        //  }
        // },

        
            // 将选中的的商品加入选中数据组中去
            // w=v.filter(item=>item.isSelected===true)
            // 给state.selectedCartListData赋新值
            // state.selectedCartListData=w   
        
      }


       
    },

    actions: {

           // 获取该用户购物车全部数据
       async get_goodsCart_all_data(context) {     
          const user_id= localStorage.getItem('user_id')   
          //   //需要传的参数  spu_id是上页传递过来的参数，固定写法      
            let url = "Home/UserGoodsCart/showlist/?user_id=" + user_id;   
            let {data : res}= await axios.get(url) 
                  // console.log('把酒问青天');
                  // console.log(res.data);
                  if(res.code==200){
                      //  给购物车全部商品赋值
               context.commit('set_goodsCartListData', res.data)
                  }

                
                 //  console.log(res);  
                  // console.log(this.goodsCart_all_list.length);
 
      },



    },

    modules: {}
};

export default GoodsCart;