
// 这个必须有，在vuex模块中，即使全局引入过，也必须再引入axios，否则，成为了个体没有定义了
// 引入后，必须用axios,而不能用this.$http。
// 5、导入axios包
import axios from "axios";  
// import  {mapState,mapGetters}  from  'vuex';
const home={
   namespaced:true,
    state(){       //state里面数据不能用this调用，必须用state调用
        return {
            // 首页banner全部数据
            bannerAlldata:[],
             // 首页banner类型数据
            // bannerType:[],
            // 用户收件人地址
            //  address:[],
        }
    },
    getters:{
        // // 首页各个楼层数据
        // // swiper数据
        // swiperdata(state){
        //     return state.bannerAlldata.filter((item)=>item.banner_typename==='swiper')
        // },
        // // ‘今日推荐’数据
        // todayCommentdata(state){
        //     return state.bannerAlldata.filter((item)=>item.banner_typename==='今日推荐')
        // },
        // // ‘BIM’数据
        // myBIMdata(state){
        //     return state.bannerAlldata.filter((item)=>item.banner_typename==='BIM天地')
        // },
        //   // ‘家用电器’数据
        //   Electricdata(state){
        //     return state.bannerAlldata.filter((item)=>item.banner_typename==='家用电器')
        // },
        //   // ‘家装绿化’数据
        //   homeDecorationGreeningdata(state){
        //     return state.bannerAlldata.filter((item)=>item.banner_typename==='家装绿化')
        // },
        //   // ‘智能农业’数据
        //   intelligentAgriculturedata(state){
        //     return state.bannerAlldata.filter((item)=>item.banner_typename==='智能农业')
        // },
        

    },
    mutations:{
         // 给banner内容全部数据赋值
        //  setBannerAlldata(state,value){
        //     // alert(236)
        //     state.bannerAlldata = value 
        //     // console.log(state.bannerAlldata);
        // },

        // 给banner类型数据赋值
        // setbannerType(state,value){
        //     state.bannerType=Array.from(value)  
        // },
    },

    actions:{
           // 获取首页的全部banner值
        // async getBannerAlldata(context) {
        //     // alert(789)
        //       let url = "Home/HomeBanneritem/showlist_banner";         
        //       let {data : res} = await  axios.get(url);
        //       console.log(res);
        //       context.commit('setBannerAlldata', res) //这个地方，必须用context调用commit
        //   },

       

        // 获取用户收件人地址数据
        // async getadress_data(context) {
        //     alert(98765)
        //       let url = "Home/user/address_showlist";         
        //       let {data : res} = await  axios.get(url);
        //       // console.log(res);
        //       context.commit('addaddress', res) //这个地方，必须用context调用commit
        //   },


        //  async getadress_data(context) {
        // //   alert(123)
        //     let url = "Home/user/address_showlist";         
        //     let {data : res} = await  axios.get(url);
        //     // console.log(res);
        //     context.commit('addaddress', res) //这个地方，必须用context调用commit
        // },

    },

    modules:{}


}
export default home