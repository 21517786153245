
<script>
export default {
    data(){
    return {
      user_name:localStorage.getItem('user_name')?localStorage.getItem('user_name'):'',
    }
  }

}
</script>

<template>
    <ul>
        <li class="header">
            <div class="pic">
                <img src="@/assets/images/f3.png" alt="">
            </div>
        </li>
        <li class="userInfo">hi!  {{user_name}}</li>
        <li class="goodsState">
            <span>购物车</span>
            <span>待收货</span>
            <span>待发货</span>
            <span>待付款</span>
            <span>待评价</span>
        </li>
        <li class="userLookthough">
            <span>宝贝收藏</span>
            <span>买过的店</span>
            <span>收藏的店</span>
            <span>我的足迹</span>
           
        </li>
        <li>
            <span>2024年三十次方新规则速递</span>
        </li>
    </ul>
  
</template>


<style lang='less'  scoped>

    ul{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        // align-items: center;
        height: 100%;
        width: 100%;
        text-align: center;
        padding: 5px;
        margin-top: 40px;
       
        li{
            flex: 1;
            line-height: 80px;
            .header{
            height: 150px;
            line-height: 150px;          
            .pic{
                height: 100px;
                width: 100px;
                border-radius: 50%;
                overflow: hidden;
                margin: 0 auto;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
            .goodsState{
                display: flex;
                justify-content: space-around;
            }
            .userInfo{
                font-size: 16px;
                font-weight: 700;
            }
            .userLookthough{
                display: flex;
                justify-content: space-around;
            }
        }
    }
</style>